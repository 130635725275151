import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
// Components
import {
  Seo,
  EnrollmentCountries,
  PickYourPerksAmbEnrModal,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'

const Enrollment = () => {
  const { userType, isAuthLoading, isAuthenticated } = useAuthContext()
  const [isPerksModalOpen, setIsPerksModalOpen] = useState(true)

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert('It looks like you are already an ambassador')
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])

  return (
    <>
      <Seo title="enrollment form" />
      {isPerksModalOpen && (
        <PickYourPerksAmbEnrModal
          open={isPerksModalOpen}
          onClose={() => {
            setIsPerksModalOpen(false)
            navigate('/enrollment-form')
          }}
        />
      )}
      {/* Note: EnrollmentCountries in not currently used but might be if more than one country exists in one magento store */}
      <EnrollmentCountries />
    </>
  )
}

export default Enrollment
